<template>
    <div>
        <h4 class="bold text-uppercase">{{ $route.name }}</h4>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    @click="handleShowPanel('order')"
                    id="order-tab"
                    data-toggle="tab"
                    href="#order"
                    role="tab"
                    aria-controls="order"
                    aria-selected="true"
                    >Tạo tiến trình</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('list')"
                    id="list-tab"
                    data-toggle="tab"
                    href="#list"
                    role="tab"
                    aria-controls="list"
                    aria-selected="false"
                    >Danh sách VIP</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('logs')"
                    id="list-tab"
                    data-toggle="tab"
                    href="#list"
                    role="tab"
                    aria-controls="list"
                    aria-selected="false"
                    >Nhật ký chạy VIP</a
                >
            </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="order" role="tabpanel" aria-labelledby="order-tab">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Link hoặc ID profile cần cài VIP:</h6>
                            </div>
                            <div class="col-md col-12">
                                <input
                                    v-model="fb_id"
                                    type="text"
                                    class="form-control input light"
                                    placeholder="Nhập link hoặc ID cần tăng"
                                />
                                <div class="card card-orange mt-2">
                                    <div class="card-body py-2">
                                        <h6 class="font-14 mb-0">
                                            <label class="mb-0 text-danger font-bold"
                                                >Get ID Facebook từ Link nhanh tại
                                                <a
                                                    href="https://findids.net/"
                                                    target="_blank"
                                                    class="bold font-14 mb-0 blue"
                                                    >Findids.net</a
                                                ></label
                                            >
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div :class="['col-md-6']">
                                <label class="bold"
                                    >Số lượng <b class="bold">{{ type_text }}</b> nhỏ nhất cần tăng mỗi bài viết</label
                                >
                                <input
                                    type="number"
                                    placeholder="Số lượng cần tăng trên mỗi bài viết"
                                    v-model="min_like"
                                    class="form-control input-light"
                                />
                            </div>
                            <div :class="['col-md-6']">
                                <label class="bold"
                                    >Số lượng <b class="bold">{{ type_text }}</b> lớn nhất cần tăng mỗi bài viết</label
                                >
                                <input
                                    type="number"
                                    placeholder="Số lượng cần tăng trên mỗi bài viết"
                                    v-model="max_like"
                                    class="form-control input-light"
                                />
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-6">
                                <label class="bold">Số ngày cần mua Vip:</label>
                                <select v-model="days" class="custom-select select-light">
                                    <option value="7">7</option>
                                    <option value="30">30</option>
                                    <option value="60">60</option>
                                    <option value="90">90</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label class="bold">Giá tiền:</label>
                                <h6 class="font-bold mb-0 font-14 user-discount py-2">
                                    {{ (price * 30) | formatNumber }} {{ currency }}/ 1 like/ 1 tháng
                                </h6>
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <div class="card card-orange">
                                <div class="card-body text-center bold">
                                    Tổng tiền của gói vip sẽ = (Số lượng Like tối đa) x (Số ngày đăng ký VIP) x (Giá mặc
                                    định của hệ thống)
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-3 col-12">
                                <h6 class="bold mb-0">Ghi chú:</h6>
                            </div>
                            <div class="col-md col-12 text-right">
                                <textarea
                                    class="form-control input-light"
                                    rows="2"
                                    v-model="notes"
                                    placeholder="Nhập nội dung ghi chú về tiến trình của bạn"
                                ></textarea>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <div class="card card-info">
                                    <div class="card-body">
                                        <h5 class="font-bold">
                                            Tổng:
                                            <span class="bold"
                                                ><number
                                                    ref="number1"
                                                    :from="0"
                                                    :to="totalPrice"
                                                    :format="theFormat"
                                                    :duration="1"
                                                    :delay="0"
                                                    easing="Power1.easeOut"
                                                />
                                                {{ currency }}</span
                                            >
                                        </h5>
                                        <h6 class="mb-0">
                                            <span
                                                >Bạn sẽ mua vip
                                                <span class="bold">{{ max_like }} {{ type_text }}</span> trong
                                                <span class="bold">{{ days }} ngày</span> với giá
                                                <span class="bold"
                                                    >{{ (price * 30) | formatNumber }} {{ currency }}/ 1 like/ 1
                                                    tháng</span
                                                >.</span
                                            >
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-12 text-center bold">
                                <button
                                    @click="order()"
                                    :class="['btn btn-block btn-dark-blue btn-lg btn-rounded bold']"
                                    type="submit"
                                >
                                    Tạo tiến trình
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card card-orange">
                            <div class="card-body color-info">
                                <p class="">
                                    - <span class="font-bold">VIP Like Clone</span> là gói Vip Like giá rẻ tự động tăng
                                    Like mỗi khi bạn đăng bài viết mới
                                    <span class="font-bold text-danger">tối đa cho 5 bài viết 1 ngày</span>.
                                </p>
                                <p class="">- Người Like là tài khoản Facebook Việt Nam có Avata.</p>
                                <p class="mb-0">
                                    - Ví dụ: Bạn mua 7 ngày, mỗi bài 50 like thì tổng tiền bạn phải trả là
                                    <span class="font-bold">{{ totalPrice | formatNumber }} {{ currency }}</span
                                    >. Hệ thống mỗi ngày sẽ tăng mỗi bài viết 50 like và tối đa tăng cho 5 bài viết.
                                </p>
                            </div>
                        </div>
                        <div class="card card-red my-3">
                            <div class="card-body color-info">
                                <p class="font-bold">Chú ý:</p>
                                <p class="font-bold">
                                    - Ngiêm cấm Buff các ID Seeding có nội dung vi phạm pháp luật, chính trị, đồ trụy...
                                    Nếu cố tình buff bạn sẽ bị trừ hết tiền và band khỏi hệ thống vĩnh viễn, và phải
                                    chịu hoàn toàn trách nhiệm trước pháp luật.
                                </p>
                                <p>
                                    - Hệ thống sử dụng 99% tài khoản người VN, fb thật để tương tác like, comment,
                                    share....
                                </p>
                                <p>
                                    - Hỗ trợ trang cá nhân và fanpage. Khi đăng bài viết mới hệ thống sẽ tự động quét và
                                    tăng tương tác theo yêu cầu của gói
                                </p>
                                <p>
                                    - Không bảo hành số lượng tương tác, Tự động hoàn tiền số ngày chưa sử dụng nếu bạn
                                    hủy vip
                                </p>
                                <p>
                                    - Vui lòng lấy đúng id vip cần tạo và check kỹ vip tránh tạo nhầm, tính năng đang
                                    trong giai đoạn thử nghiệm nên sẽ không hoàn tiền nếu bạn tạo nhầm
                                </p>
                                <p class="mb-0">- Tốc độ tăng nhanh chậm ngẫu nhiên tránh bị facebook phát hiện.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="list" role="tabpanel" aria-labelledby="list-tab">
                <datatable :columns="panel == 'list' ? columnsTableLogs : columnsTableLogsOrder" :rows="list_orders">
                    <th v-if="panel != 'logs'" slot="thead-tr">
                        Thao Tác
                    </th>
                    <td
                        v-if="panel != 'logs'"
                        slot="tbody-tr"
                        slot-scope="props"
                        class="text-center"
                        style="vertical-align: middle;"
                    >
                        <button
                            @click.stop.prevent="reNew_Order(props.row)"
                            type="button"
                            :class="['btn btn-sm btn-success mr-2']"
                        >
                            <i class="fas fa-cart-plus"></i>
                            Gia Hạn
                        </button>
                        <button
                            @click.stop.prevent="deleteVip(props.row)"
                            type="button"
                            :class="['btn btn-sm btn-danger align-items-center']"
                        >
                            <i class="fa fa-trash"></i>
                            Hủy Vip
                        </button>
                    </td>
                </datatable>
            </div>
        </div>
        <div
            v-if="reNewOrder"
            class="modal fade"
            id="reNew_Order"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Gia hạn VIP Like <span class="bold">{{ reNewOrder.fb_name }}</span>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="form-group col-md-6 col-12">
                                <label
                                    >Số lượng <b class="font-bold">{{ type_text }}</b> nhỏ nhất cần tăng</label
                                >
                                <input
                                    type="number"
                                    placeholder="Số lượng nhỏ nhất cần tăng"
                                    v-model="reNewOrder.min_like"
                                    class="form-control form-control-line input-gray"
                                />
                            </div>
                            <div class="form-group col-md-6 col-12">
                                <label
                                    >Số lượng <b class="font-bold">{{ type_text }}</b> lớn nhất cần tăng</label
                                >
                                <input
                                    type="number"
                                    placeholder="Số lượng lớn nhất cần tăng"
                                    v-model="reNewOrder.max_like"
                                    class="form-control form-control-line input-gray"
                                />
                            </div>
                            <div class="form-group col-md-6 col-12">
                                <label>Số ngày cần mua Vip:</label>
                                <input
                                    type="number"
                                    placeholder="Số lượng bài viết cần tăng trong 1 ngày"
                                    v-model="reNewOrder.days"
                                    class="form-control form-control-line input-gray"
                                />
                            </div>
                            <div class="form-group col-md-6 col-12">
                                <label class="bold">Giá tiền:</label>
                                <h6 class="bold mb-0 font-14 user-discount py-2">
                                    {{ (price * 30) | formatNumber }} mCoin/ 1 like/ 1 tháng
                                </h6>
                            </div>
                            <div class="form-group col-md-12">
                                <label class="mr-3">Ghi chú:</label>
                                <textarea
                                    class="form-control input-gray"
                                    rows="2"
                                    v-model="reNewOrder.notes"
                                    placeholder="Nhập nội dung ghi chú về tiến trình của bạn"
                                ></textarea>
                            </div>
                            <div class="form-group col-md-12">
                                <div class="card card-orange mt-4">
                                    <div class="card-body">
                                        <p class="text-danger mb-0">
                                            Tổng tiền của gói vip sẽ = (Số lượng Like tối đa) x (Số ngày đăng ký VIP) x
                                            (Giá mặc định của hệ thống)
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-12 text-center">
                                <div class="card card-info">
                                    <div class="card-body">
                                        <h5 class="font-bold">
                                            Tổng:
                                            <span class="bold green"
                                                ><number
                                                    ref="number1"
                                                    :from="0"
                                                    :to="totalPrice"
                                                    :format="theFormat"
                                                    :duration="1"
                                                    :delay="0"
                                                    easing="Power1.easeOut"
                                                />
                                                mCoin</span
                                            >
                                        </h5>
                                        <h6 class="mb-0 bold">
                                            <span
                                                >Bạn sẽ mua VIP
                                                <span class="green">{{ reNewOrder.max_like }} Like</span> trong
                                                <span class="green">{{ reNewOrder.days }} ngày.</span></span
                                            >
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" :class="['btn btn-danger']" data-dismiss="modal">Hủy Bỏ</button>
                        <button @click="reNew_Order_Confirm()" type="button" :class="['btn btn-success']">
                            Đồng Ý
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { catchError, formatNumber } from "../../../../helpers"
import {
    orderVipClone,
    convertUsernameToFbId,
    getListVipClone,
    getLogVip,
    deleteVipClone
} from "../../../../api/services/facebook"
import datatable from "../../../../components/datatable/datatable"
export default {
    name: "facebook-order",
    components: {
        datatable
    },
    data() {
        return {
            list_orders: [],
            type: "vip_seeding_like",
            quantity: 1,
            object_id_search: "",
            gender: "",
            fb_id: "",
            columnsTableLogs: [
                { label: "#", field: "index", index: true },
                { label: "Username", field: "username" },
                { label: "Uid", field: "fb_id", fb_link: true },
                { label: "Name", field: "fb_name" },
                { label: "Min Like", field: "min_like", numeric: true },
                { label: "Max Like", field: "max_like", numeric: true },
                { label: "Số ngày", field: "days", numeric: true },
                { label: "Tổng Tiền", field: "prices", numeric: true },
                { label: "Hết Hạn", field: "time_expired", timeago: true },
                { label: "Khởi Tạo", field: "created_at", timeago: true },
                { label: "Ghi chú", field: "notes", textarea: true }
            ],
            columnsTableLogsOrder: [
                { label: "#", field: "index", index: true },
                { label: "Username", field: "username" },
                { label: "Uid", field: "fb_id", fb_link: true },
                { label: "Object Id", field: "object_id", fb_link: true },
                { label: "Loại", field: "type", html: true },
                { label: "Khởi Tạo", field: "created_at", timeago: true }
            ],
            price: 0,
            rows: [],
            days: 7,
            max_post_daily: 2,
            min_like: 50,
            max_like: 50,
            list_messages: [],
            notes: "",
            total_post: 10,
            panel: "",
            reNewOrder: {},
            price_default: 0
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        },
        prices() {
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 70)
            return tool.prices
        },
        priceSingle() {
            let price = parseInt(this.price)
            return Math.ceil(price)
        },
        totalPrice() {
            return this.priceSingle * this.max_like * this.days
        },
        type_text() {
            return "like"
        },
        router() {
            return this.$route.path
        }
    },
    watch: {
        async fb_id() {
            if (this.fb_id.indexOf("facebook.com") > -1) {
                this.fb_id = await this.getFbId(this.fb_id)
            }
        }
    },
    created() {
        this.type = "vip_seeding_like_clone"
        let prices = this.prices
        let price = prices.find(price => price.package_name === this.type)
        if (price) {
            this.price_default = price.price
            this.price = price.price
        }
    },
    methods: {
        reNew_Order: async function(item) {
            this.reNewOrder = Object.assign({}, item)
            // eslint-disable-next-line no-undef
            $("#reNew_Order").modal("show")
        },
        reNew_Order_Confirm: async function() {
            if (parseInt(this.reNewOrder.min_like) > parseInt(this.reNewOrder.max_like)) {
                this.$swal("Lỗi", "Min like phải nhỏ hơn max like", "error")
                return
            }
            let check = await this.$swal({
                title: "Xác nhận?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                reverseButtons: true,
                html: `Bạn có chắc chắn muốn đăng ký gói VIP ${this.type_text}: ${this.reNewOrder.days +
                    " ngày"} <br> Tối đa ${this.reNewOrder.max_like} ${
                    this.type_text
                } / bài viết <br> Tổng tiền: ${this.theFormat(this.totalPrice)} mCoin`
            })
            if (!check.value) {
                return
            }
            let postData = {
                fb_id: this.reNewOrder.fb_id,
                min_like: this.reNewOrder.min_like,
                max_like: this.reNewOrder.max_like,
                quantity: this.reNewOrder.max_like,
                notes: this.reNewOrder.notes,
                days: this.reNewOrder.days,
                renew: true
            }
            let data = await orderVipClone(postData)
            if (data.status === 200 && data.success) {
                this.$swal("Thành công", data.message, "success")
                // eslint-disable-next-line no-undef
                $("#reNew_Order").modal("hide")
            } else {
                let noti = ""
                if (data.message) {
                    if (catchError(data) === data.message) {
                        noti = data.message
                    } else noti = catchError(data) + "</br></br>" + data.message
                } else noti = catchError(data)
                this.$swal("Lỗi", noti, "error")
            }
        },
        order: async function() {
            if (this.fb_id.length < 5) {
                this.$swal("Lỗi", "Vui lòng nhập Id cần cài VIP", "error")
                return
            }
            if (parseInt(this.min_like) > parseInt(this.max_like)) {
                this.$swal("Lỗi", "Min like phải nhỏ hơn max like", "error")
                return
            }
            let check = await this.$swal({
                title: "Xác nhận?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                reverseButtons: true,
                html: `Bạn có chắc chắn muốn đăng ký gói VIP Like Giá Rẻ: ${this.days + " ngày"} <br> - ${
                    this.max_like
                } ${this.type_text} / bài viết <br> - 5 bài viết / ngày <br> Tổng tiền: ${formatNumber(
                    this.totalPrice
                )}`
            })
            if (!check.value) {
                return
            }
            let postData = {
                fb_id: this.fb_id,
                days: this.days,
                min_like: this.min_like,
                max_like: this.max_like,
                quantity: this.max_like,
                notes: this.notes
            }
            let data = await orderVipClone(postData)
            if (data.status === 200 && data.success) {
                this.$swal("Thành công", data.message, "success")
            } else {
                if (data.message) {
                    this.$swal("Lỗi", data.message, "error")
                } else {
                    this.$swal("Lỗi", catchError(data), "error")
                }
            }
        },
        deleteVip: async function(vip) {
            let check = await this.$swal({
                title: "Xác nhận?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "OK",
                html: `Bạn có chắc chắn muốn hủy gói VIP này? <br> Bạn sẽ được hoàn lại số tiền những ngày vip chưa sử dụng và bị trừ thêm 10.000 ${this.currency} tiền phí hủy để tránh spam`
            })
            if (!check.value) {
                return
            }
            let data = await deleteVipClone(vip.id)
            if (data.status === 200 && data.success) {
                let index = this.list_orders.indexOf(vip)
                this.list_orders.splice(index, 1)
                this.$swal("Thông tin", "Bạn đã hủy vip thành công", "success")
            } else {
                this.$swal("Thông tin", data.message || "Bạn đã hủy vip thất bại", "error")
            }
        },
        getListVipSeedingOrder: async function() {
            let data = await getListVipClone()
            if (data.data) {
                this.list_orders = data.data.map(order => {
                    order.list_messages_html = order.list_messages ? JSON.parse(order.list_messages).join("\n") : ""
                    order.status_html = order.is_refund
                        ? `<span class="badge badge-warning">Hoàn tiền</span>`
                        : (order.count_is_run >= order.quantity
                              ? `<span class="badge badge-success">Hoàn thành</span>`
                              : `<span class="badge text-white badge-primary">Đang chạy</span>`) +
                          `<span class="badge badge-success ml-1">${formatNumber(
                              order.count_success
                          )}</span><span class="badge badge-danger ml-1">${formatNumber(order.count_is_run)}</span>`
                    return order
                })
            }
        },
        getLogVipSeedingOrder: async function() {
            let data = null
            if (this.object_id_search) {
                data = await getLogVip(this.object_id_search, "vip_seeding_like_clone", this.limit_logs)
            } else {
                data = await getLogVip("", "vip_seeding_like_clone", this.limit_logs)
            }
            if (data.data.data) {
                this.list_orders = data.data.data.map(order => {
                    order.type_html = order.type.toUpperCase()
                    order.list_messages_html = order.list_messages ? JSON.parse(order.list_messages).join("\n") : ""
                    order.status_html = order.is_refund
                        ? `<span class="badge badge-warning">Hoàn tiền</span>`
                        : (order.count_is_run >= order.quantity
                              ? `<span class="badge badge-success">Hoàn thành</span>`
                              : `<span class="badge text-white badge-primary">Đang chạy</span>`) +
                          `<span class="badge badge-success ml-1">${formatNumber(
                              order.count_success
                          )}</span><span class="badge badge-danger ml-1">${formatNumber(order.count_is_run)}</span>`
                    return order
                })
            }
        },
        handleShowPanel: async function(panel) {
            this.panel = panel
            if (panel === "list") {
                this.list_orders = []
                await this.getListVipSeedingOrder()
            }
            if (panel === "logs") {
                this.list_orders = []
                await this.getLogVipSeedingOrder()
            }
        },
        theFormat(number) {
            return number
                ? Math.round(Number(number))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
        },
        getFbId: async function(link) {
            if (link.match(/facebook/)) {
                this.$toastr.success("Hệ thống đang tự động get id cho link facebook bạn nhập vào!")
                if (link.indexOf("profile.php") > -1) {
                    var regex = (link + "&").match(/id=(\d*?)&/)
                    if (regex.length === 2) {
                        this.$toastr.success("Đã tự động get id cho bạn")
                        return regex[1]
                    } else {
                        return false
                    }
                } else {
                    var username = link.replace(
                        /https|http|\/|\\|www\.|mbasic\.|m\.facebook.com|facebook\.com|:|\?.*/gm,
                        ""
                    )
                    let data = await convertUsernameToFbId(username)
                    if (data.status === 200 && data.success) {
                        this.$toastr.success("Đã tự động get id cho bạn")
                        return data.data.id
                    } else {
                        return false
                    }
                }
            } else return true
        }
    }
}
</script>
